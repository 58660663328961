const API_ROOT = 'https://api-uat.bentobento.info';
const SOCKET_ROOT = 'https://api-uat.bentobento.info/admin-backend';
const BENTO_URL = 'https://uat.bentobento.info';
const GA_MEASUREMENT_ID = undefined;
const ENABLE_REPORT_IMPROVEMENTS = true;
const ENABLE_SURVEY_WIZARD = true;

export {
  API_ROOT,
  BENTO_URL,
  ENABLE_REPORT_IMPROVEMENTS,
  ENABLE_SURVEY_WIZARD,
  GA_MEASUREMENT_ID,
  SOCKET_ROOT,
};
